import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  array,
  arrayOf,
  bool,
  func,
  node,
  number,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import loadable from '@loadable/component';
import classNames from 'classnames';
import omit from 'lodash/omit';
import Select from 'react-select';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import {
  displayDeliveryPickup,
  displayDeliveryShipping,
  displayPrice,
} from '../../util/configHelpers';
import {
  propTypes,
  LISTING_STATE_CLOSED,
  LINE_ITEM_NIGHT,
  LINE_ITEM_DAY,
  LINE_ITEM_ITEM,
  LINE_ITEM_HOUR,
  STOCK_MULTIPLE_ITEMS,
  STOCK_INFINITE_MULTIPLE_ITEMS,
} from '../../util/types';
import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import { userDisplayNameAsString } from '../../util/data';
import {
  INQUIRY_PROCESS_NAME,
  getSupportedProcessesInfo,
  isBookingProcess,
  isPurchaseProcess,
  resolveLatestProcessName,
} from '../../transactions/transaction';
import { MdVerified } from 'react-icons/md';
import {
  ModalInMobile,
  PrimaryButton,
  AvatarSmall,
  H1,
  H2,
  Button,
  SecondaryButton,
  ExternalLink,
  Modal,
  NamedLink,
  Verified,
  IconSpinner,
} from '../../components';

import css from './OrderPanel.module.css';
import SectionLikes from '../../containers/ListingPage/SectionLikes';
import {
  DISCOVER_LISTING_TYPE,
  ROOM_LISTING_TYPE,
  SERVICE_LISTING,
  WORKSHOP_LISTING_TYPE,
} from '../../config/configListing';
import { useDispatch } from 'react-redux';
import {
  fetchUsersAllBookings,
  showListingIfAffiation,
} from '../../containers/ListingPage/ListingPage.duck';
import { checkMembership } from '../../config/configMembership';
import { useConfiguration } from '../../context/configurationContext';
function isEmpty(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

const BookingTimeForm = loadable(() =>
  import(/* webpackChunkName: "BookingTimeForm" */ './BookingTimeForm/BookingTimeForm')
);
const BookingDatesForm = loadable(() =>
  import(/* webpackChunkName: "BookingDatesForm" */ './BookingDatesForm/BookingDatesForm')
);
const InquiryWithoutPaymentForm = loadable(() =>
  import(
    /* webpackChunkName: "InquiryWithoutPaymentForm" */ './InquiryWithoutPaymentForm/InquiryWithoutPaymentForm'
  )
);
const ProductOrderForm = loadable(() =>
  import(/* webpackChunkName: "ProductOrderForm" */ './ProductOrderForm/ProductOrderForm')
);

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;
const TODAY = new Date();

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openOrderModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), orderOpen: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeOrderModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'orderOpen');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const handleSubmit = (
  isOwnListing,
  isClosed,
  isInquiryWithoutPayment,
  onSubmit,
  history,
  location
) => {
  // TODO: currently, inquiry-process does not have any form to ask more order data.
  // We can submit without opening any inquiry/order modal.
  return isInquiryWithoutPayment
    ? () => onSubmit({})
    : () => openOrderModal(isOwnListing, isClosed, history, location);
};

const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

const PriceMaybe = props => {
  const {
    price,
    publicData,
    validListingTypes,
    intl,
    marketplaceCurrency,
    showCurrencyMismatch = false,
  } = props;
  const { listingType, unitType } = publicData || {};
  const isWorkshop = listingType === WORKSHOP_LISTING_TYPE;
  // console.log('isWorkshop', isWorkshop);
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice || !price) {
    return null;
  }

  // Get formatted price or currency code if the currency does not match with marketplace currency
  const { formattedPrice, priceTitle } = priceData(price, marketplaceCurrency, intl);
  // TODO: In CTA, we don't have space to show proper error message for a mismatch of marketplace currency
  //       Instead, we show the currency code in place of the price
  return showCurrencyMismatch ? (
    <div className={css.priceContainerInCTA}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      {isWorkshop ? (
        <div className={css.perUnitInCTA}>
          <FormattedMessage id="ListingCard.perWorkshop" />
        </div>
      ) : (
        <div className={css.perUnitInCTA}>
          <FormattedMessage id="OrderPanel.perUnit" values={{ unitType }} />
        </div>
      )}
    </div>
  ) : (
    <div className={css.priceContainer}>
      <p className={css.price}>{formatMoney(intl, price)}</p>
      {isWorkshop ? (
        <div className={css.perUnit}>
          <FormattedMessage id="ListingCard.perWorkshop" />
        </div>
      ) : (
        <div className={css.perUnit}>
          <FormattedMessage id="OrderPanel.perUnit" values={{ unitType }} />
        </div>
      )}
    </div>
  );
};

const OrderPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    validListingTypes,
    lineItemUnitType: lineItemUnitTypeMaybe,
    isOwnListing,
    onSubmit,
    title,
    titleDesktop,
    author,
    authorLink,
    onManageDisableScrolling,
    onFetchTimeSlots,
    monthlyTimeSlots,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    onContactUser,
    lineItems,
    marketplaceCurrency,
    dayCountAvailableForBooking,
    marketplaceName,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    payoutDetailsWarning,
    SectionLikes,
    onToggleFavorites,
    currentUser,
    onFetchBookings,
    fetchBookingsLoading,
    fetchBookingsError,
    bookings,
    membership,
    fetchExceptionLoading,
    fetchExceptionError,
    exception,
    fetchUsersAllBookingsLoading,
    fetchUsersAllBookingsError,
    usersAllBookings,
    stripeAccount,
    stripeAccountVerified,
    fetchingUser,
    fetchingUserError,
  } = props;

  const [calendarLink, setCalendarLink] = useState(null);
  const [isOpen, setOpen] = useState(false);
  const publicData = listing?.attributes?.publicData || {};
  const dispatch = useDispatch();
  const config = useConfiguration();
  useEffect(() => {
    if (author) {
      dispatch(
        fetchUsersAllBookings({
          userId: author?.id?.uuid,
        })
      );
    }
  }, []);

  const { listingType, unitType, transactionProcessAlias = '' } = publicData || {};
  const processName = resolveLatestProcessName(transactionProcessAlias.split('/')[0]);
  const lineItemUnitType = lineItemUnitTypeMaybe || `line-item/${unitType}`;
  const price = listing?.attributes?.price;
  const isPaymentProcess = processName !== INQUIRY_PROCESS_NAME;
  const showPriceMissing = isPaymentProcess && !price;
  const PriceMissing = () => {
    return (
      <p className={css.error}>
        <FormattedMessage id="OrderPanel.listingPriceMissing" />
      </p>
    );
  };
  const showInvalidCurrency = isPaymentProcess && price?.currency !== marketplaceCurrency;
  const InvalidCurrency = () => {
    return (
      <p className={css.error}>
        <FormattedMessage id="OrderPanel.listingCurrencyInvalid" />
      </p>
    );
  };

  const timeZone = listing?.attributes?.availabilityPlan?.timezone;
  const isClosed = listing?.attributes?.state === LISTING_STATE_CLOSED;
  const { attributes } = author || {};
  const { profile } = attributes || {};
  const { metadata, privateData } = profile || {};
  const { affiliation: authorMembership = false } = metadata || {};
  const authorMembershipActive = checkMembership(author);
  const isBooking = isBookingProcess(processName);
  const shouldHaveBookingTime = isBooking && [LINE_ITEM_HOUR].includes(lineItemUnitType);
  const isService = listingType === SERVICE_LISTING || listingType === DISCOVER_LISTING_TYPE;
  const showInquiryForm = processName === INQUIRY_PROCESS_NAME;
  const showBookingTimeForm = isService && !showInquiryForm;
  // authorMembershipActive
  //   ?
  // (shouldHaveBookingTime && !isClosed && timeZone) || authorMembership;

  const showBookingTimeFormCheck = shouldHaveBookingTime && !isClosed && timeZone;
  const isWorkshop = listingType === WORKSHOP_LISTING_TYPE;
  const isRoomForRent = listingType === ROOM_LISTING_TYPE;
  const shouldHaveBookingDates =
    isBooking && [LINE_ITEM_DAY, LINE_ITEM_NIGHT].includes(lineItemUnitType);
  const showBookingDatesForm = shouldHaveBookingDates && !isClosed && timeZone;

  // The listing resource has a relationship: `currentStock`,
  // which you should include when making API calls.
  const isPurchase = isPurchaseProcess(processName);
  const currentStock = listing.currentStock?.attributes?.quantity;
  const isOutOfStock = isPurchase && lineItemUnitType === LINE_ITEM_ITEM && currentStock === 0;

  // Show form only when stock is fully loaded. This avoids "Out of stock" UI by
  // default before all data has been downloaded.
  const showProductOrderForm = isPurchase && typeof currentStock === 'number';

  const supportedProcessesInfo = getSupportedProcessesInfo();
  const isKnownProcess = supportedProcessesInfo.map(info => info.name).includes(processName);

  const { pickupEnabled, shippingEnabled } = listing?.attributes?.publicData || {};

  const listingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const displayShipping = displayDeliveryShipping(listingTypeConfig);
  const displayPickup = displayDeliveryPickup(listingTypeConfig);
  const allowOrdersOfMultipleItems = [STOCK_MULTIPLE_ITEMS, STOCK_INFINITE_MULTIPLE_ITEMS].includes(
    listingTypeConfig?.stockType
  );

  const { calendar = [] } = privateData || {};
  const calenderLink = calendar;
  const calenderOptions = Array.isArray(calendar)
    ? calendar
        ?.map((option, index) => ({
          label: option?.title ? option?.title : null,
          value: option?.link + index,
          show: option?.check,
          link: option?.link
            ? option?.link?.startsWith('http')
              ? option.link
              : `https://${option.link}`
            : null,
        }))
        ?.filter(option => option.show)
        ?.filter(option => option.value)
        ?.filter(option => option.label)
    : [];
  const showClosedListingHelpText = listing.id && isClosed;
  const isOrderOpen = !!parse(location.search).orderOpen;

  const subTitleText = showClosedListingHelpText
    ? intl.formatMessage({ id: 'OrderPanel.subTitleClosedListing' })
    : null;

  const authorDisplayName = userDisplayNameAsString(author, '');

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.orderTitle);

  // Favorite button
  const isFavorite = currentUser?.attributes.profile.privateData.favorites?.includes(
    listing.id.uuid
  );

  const { attributes: authorAttributes } = author || {};
  const { stripeConnected } = authorAttributes || {};
  const isStripeConnectedAndHaveAvailableTimeSlots = authorMembershipActive
    ? stripeConnected && !isEmpty(monthlyTimeSlots)
    : false;

  const showCalenderLinks =
    authorMembership &&
    calendar &&
    calenderOptions?.length &&
    isService &&
    !isStripeConnectedAndHaveAvailableTimeSlots;
  const IconStar = ({ fill = 'lightgrey' }) => {
    return (
      <svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <g id="star-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Shape" transform="translate(-240.000000, -48.000000)" fillRule="nonzero">
              <g id="star_fill" transform="translate(240.000000, 48.000000)">
                <path
                  //d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z"
                  d="M12 6.00019C10.2006 3.90317 7.19377 3.2551 4.93923 5.17534C2.68468 7.09558 2.36727 10.3061 4.13778 12.5772C5.60984 14.4654 10.0648 18.4479 11.5249 19.7369C11.6882 19.8811 11.7699 19.9532 11.8652 19.9815C11.9483 20.0062 12.0393 20.0062 12.1225 19.9815C12.2178 19.9532 12.2994 19.8811 12.4628 19.7369C13.9229 18.4479 18.3778 14.4654 19.8499 12.5772C21.6204 10.3061 21.3417 7.07538 19.0484 5.17534C16.7551 3.2753 13.7994 3.90317 12 6.00019Z"
                  id="MingCute"
                  fillRule="nonzero"
                ></path>
                <path
                  //d="M10.9198,2.8677 C11.402,2.03987 12.598,2.03987 13.0801,2.8677 L15.8751,7.66643 L21.3027,8.84175 C22.239,9.0445 22.6086,10.1819 21.9703,10.8963 L18.2701,15.0374 L18.8295,20.5625 C18.926,21.5156 17.9585,22.2186 17.0818,21.8323 L12,19.5929 L6.91816,21.8323 C6.04149,22.2186 5.07395,21.5156 5.17046,20.5625 L5.72987,15.0374 L2.02972,10.8963 C1.3914,10.1819 1.76097,9.0445 2.69728,8.84175 L8.12484,7.66643 L10.9198,2.8677 Z"
                  d="M19.3 5.71002C18.841 5.24601 18.2943 4.87797 17.6917 4.62731C17.0891 4.37666 16.4426 4.2484 15.79 4.25002C15.1373 4.2484 14.4909 4.37666 13.8883 4.62731C13.2857 4.87797 12.739 5.24601 12.28 5.71002L12 6.00002L11.72 5.72001C10.7917 4.79182 9.53273 4.27037 8.22 4.27037C6.90726 4.27037 5.64829 4.79182 4.72 5.72001C3.80386 6.65466 3.29071 7.91125 3.29071 9.22002C3.29071 10.5288 3.80386 11.7854 4.72 12.72L11.49 19.51C11.6306 19.6505 11.8212 19.7294 12.02 19.7294C12.2187 19.7294 12.4094 19.6505 12.55 19.51L19.32 12.72C20.2365 11.7823 20.7479 10.5221 20.7442 9.21092C20.7405 7.89973 20.2218 6.64248 19.3 5.71002Z"
                  id="star"
                  //id="star"
                  fill={fill}
                ></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  };

  const toggleFavorites = () => onToggleFavorites(isFavorite);

  const favoriteButton = isFavorite ? (
    <div className={css.favoriteButton} onClick={toggleFavorites} title="Remove from favorites">
      <IconStar fill="#ff8888" />
    </div>
  ) : (
    <div className={css.selected} onClick={toggleFavorites} title="Add to favorites">
      <IconStar />
    </div>
  );

  return (
    <div className={classes}>
      {/* {sectionLikes} */}

      <ModalInMobile
        containerClassName={css.modalContainer}
        id="OrderFormInModal"
        isModalOpenOnMobile={isOrderOpen}
        onClose={() => closeOrderModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        <div className={css.modalHeading}>
          <H1 className={css.heading}>{title}</H1>
        </div>

        <div className={css.orderHeading}>
          {titleDesktop ? titleDesktop : <H2 className={titleClasses}>{title}</H2>}
          {subTitleText ? <div className={css.orderHelp}>{subTitleText}</div> : null}
          {favoriteButton} {/* Favorite button*/}
        </div>

        <PriceMaybe
          price={price}
          publicData={publicData}
          validListingTypes={validListingTypes}
          intl={intl}
          marketplaceCurrency={marketplaceCurrency}
        />

        <div className={css.author}>
          <AvatarSmall user={author} className={css.providerAvatar} />
          <span className={css.providerNameLinked}>
            {authorMembershipActive ? (
              <Verified
                name={<FormattedMessage id="OrderPanel.author" values={{ name: authorLink }} />}
              />
            ) : (
              <FormattedMessage id="OrderPanel.author" values={{ name: authorLink }} />
            )}
            {/* <FormattedMessage id="OrderPanel.author" values={{ name: authorLink }} />
            {authorMembershipActive ? 'Verified' : null} */}
          </span>
          <span className={css.providerNamePlain}>
            <FormattedMessage id="OrderPanel.author" values={{ name: authorDisplayName }} />
          </span>
        </div>

        {showPriceMissing ? (
          <PriceMissing />
        ) : showInvalidCurrency ? (
          <InvalidCurrency />
        ) : showInquiryForm ? (
          <InquiryWithoutPaymentForm
            formId="OrderPanelInquiryForm"
            onSubmit={onSubmit}
            author={author}
            currentUser={currentUser}
            listingId={listing.id?.uuid}
          />
        ) : showBookingTimeForm || isWorkshop || isRoomForRent ? (
          <BookingTimeForm
            className={css.bookingForm}
            formId="OrderPanelBookingTimeForm"
            lineItemUnitType={lineItemUnitType}
            // initialValues={{ material: [] }}
            onSubmit={onSubmit}
            price={price}
            marketplaceCurrency={marketplaceCurrency}
            dayCountAvailableForBooking={dayCountAvailableForBooking}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            stripeAccount={stripeAccount}
            monthlyTimeSlots={monthlyTimeSlots}
            isWorkshop={isWorkshop}
            onFetchTimeSlots={onFetchTimeSlots}
            startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            timeZone={timeZone}
            marketplaceName={marketplaceName}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            payoutDetailsWarning={payoutDetailsWarning}
            listing={listing}
            onFetchBookings={onFetchBookings}
            fetchBookingsLoading={fetchBookingsLoading}
            fetchBookingsError={fetchBookingsError}
            bookings={bookings}
            membership={membership}
            fetchExceptionLoading={fetchExceptionLoading}
            fetchExceptionError={fetchExceptionError}
            exception={exception}
            fetchUsersAllBookingsLoading={fetchUsersAllBookingsLoading}
            fetchUsersAllBookingsError={fetchUsersAllBookingsError}
            usersAllBookings={usersAllBookings}
            isService={isService}
            currentUser={currentUser}
            author={author}
            onContactUser={onContactUser}
            showBookingTimeFormCheck={showBookingTimeFormCheck}
            isRoomForRent={isRoomForRent}
            stripeAccountVerified={stripeAccountVerified}
            fetchingUser={fetchingUser}
            fetchingUserError={fetchingUserError}
          />
        ) : showBookingDatesForm ? (
          <BookingDatesForm
            className={css.bookingForm}
            formId="OrderPanelBookingDatesForm"
            lineItemUnitType={lineItemUnitType}
            onSubmit={onSubmit}
            price={price}
            marketplaceCurrency={marketplaceCurrency}
            dayCountAvailableForBooking={dayCountAvailableForBooking}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            monthlyTimeSlots={monthlyTimeSlots}
            onFetchTimeSlots={onFetchTimeSlots}
            timeZone={timeZone}
            marketplaceName={marketplaceName}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            payoutDetailsWarning={payoutDetailsWarning}
          />
        ) : showProductOrderForm ? (
          <ProductOrderForm
            formId="OrderPanelProductOrderForm"
            onSubmit={onSubmit}
            price={price}
            marketplaceCurrency={marketplaceCurrency}
            currentStock={currentStock}
            allowOrdersOfMultipleItems={allowOrdersOfMultipleItems}
            pickupEnabled={pickupEnabled && displayPickup}
            shippingEnabled={shippingEnabled && displayShipping}
            displayDeliveryMethod={displayPickup || displayShipping}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            marketplaceName={marketplaceName}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            onContactUser={onContactUser}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            payoutDetailsWarning={payoutDetailsWarning}
          />
        ) : !isKnownProcess ? (
          <p className={css.errorSidebar}>
            <FormattedMessage id="OrderPanel.unknownTransactionProcess" />
          </p>
        ) : null}
      </ModalInMobile>
      <div className={css.openOrderForm}>
        <PriceMaybe
          price={price}
          publicData={publicData}
          validListingTypes={validListingTypes}
          intl={intl}
          marketplaceCurrency={marketplaceCurrency}
          showCurrencyMismatch
        />

        {isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="OrderPanel.closedListingButtonText" />
          </div>
        ) : (
          <div className={css.buttonContainer}>
            {(authorMembershipActive && stripeConnected) || showInquiryForm ? (
              <PrimaryButton
                onClick={handleSubmit(
                  isOwnListing,
                  isClosed,
                  showInquiryForm,
                  onSubmit,
                  history,
                  location
                )}
                disabled={isOutOfStock}
              >
                {isBooking ? (
                  <FormattedMessage id="OrderPanel.ctaButtonMessageBooking" />
                ) : isOutOfStock ? (
                  <FormattedMessage id="OrderPanel.ctaButtonMessageNoStock" />
                ) : isPurchase ? (
                  <FormattedMessage id="OrderPanel.ctaButtonMessagePurchase" />
                ) : (
                  <FormattedMessage id="OrderPanel.ctaButtonMessageInquiry" />
                )}
              </PrimaryButton>
            ) : null}
            {showCalenderLinks ? (
              // showInquiryForm
              <div>
                {/* {calenderOptions?.length > 0 ? (
                  <>
                    <Select
                      name="calendar"
                      options={calenderOptions}
                      onChange={e => setCalendarLink(e.value)}
                      isSearchable={false}
                      value={calenderOptions.find(option => option.value === calendarLink)}
                    />
                    <br />
                  </>
                ) : null} */}
                <PrimaryButton
                  type="button"
                  // disabled={!calendarLink}
                  onClick={() => setOpen(true)}
                  className={css.bookNowButton}
                >
                  {/* <ExternalLink href={calendarLink} target="__blank" className={css.bookNow}> */}
                  <FormattedMessage id="InquiryWithoutPaymentForm.InquiryWithoutPaymentForm.bookNow" />
                  {/* </ExternalLink> */}
                </PrimaryButton>
                {/* {authorMembershipActive ? ( */}
                <p className={css.orText}>
                  <FormattedMessage id="InquiryWithoutPaymentForm.InquiryWithoutPaymentForm.orText" />
                </p>
                {/* ) : null} */}
              </div>
            ) : null}
            {!showInquiryForm ? (
              <div className={css.desktop}>
                <PrimaryButton
                  type="button"
                  className={css.bookNowButton}
                  onClick={() => {
                    onContactUser();
                  }}
                >
                  {intl.formatMessage({ id: 'BookingTimeForm.BookingTimeForm.chatButtonLabel' })}
                </PrimaryButton>
              </div>
            ) : null}
          </div>
        )}
      </div>
      <Modal
        {...props}
        isOpen={isOpen}
        onClose={() => {
          setOpen(false);
        }}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div style={{ margin: '1rem' }}>
          {calenderOptions?.length > 0 ? (
            <div className={css.reactSelect}>
              <Select
                name="calendar"
                options={calenderOptions}
                onChange={e => setCalendarLink(e.link)}
                // onChange={e => setCalendarLink(e.value)}
                isSearchable={false}
                value={calenderOptions.find(option => option.value === calendarLink)}
              />
              <br />

              <PrimaryButton type="button" disabled={!calendarLink} className={css.bookNowButton}>
                {currentUser ? (
                  <ExternalLink href={calendarLink} target="__blank" className={css.bookNow}>
                    <FormattedMessage id="InquiryWithoutPaymentForm.InquiryWithoutPaymentForm.bookNow" />
                  </ExternalLink>
                ) : (
                  <NamedLink
                    name="LoginPage"
                    className={css.bookNow}
                    to={{ listingId: listing?.id?.uuid }}
                  >
                    <FormattedMessage id="InquiryWithoutPaymentForm.InquiryWithoutPaymentForm.bookNow" />
                  </NamedLink>
                )}
              </PrimaryButton>
            </div>
          ) : null}
        </div>
      </Modal>
    </div>
  );
};

OrderPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  authorLink: null,
  payoutDetailsWarning: null,
  titleDesktop: null,
  subTitle: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

OrderPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  validListingTypes: arrayOf(
    shape({
      listingType: string.isRequired,
      transactionType: shape({
        process: string.isRequired,
        alias: string.isRequired,
        unitType: string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  isOwnListing: bool,
  author: oneOfType([propTypes.user, propTypes.currentUser]).isRequired,
  authorLink: node,
  payoutDetailsWarning: node,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  titleDesktop: node,
  subTitle: oneOfType([node, string]),
  onManageDisableScrolling: func.isRequired,

  onFetchTimeSlots: func.isRequired,
  monthlyTimeSlots: object,
  onFetchTransactionLineItems: func.isRequired,
  onContactUser: func,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  marketplaceCurrency: string.isRequired,
  dayCountAvailableForBooking: number.isRequired,
  marketplaceName: string.isRequired,

  // Favorite button
  onToggleFavorites: func.isRequired,
  currentUser: propTypes.currentUser.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(withRouter, injectIntl)(OrderPanel);
